<template>
  <div class="comment">
    <h5 class="comment__header">
      Comments
      <span class="badge badge-dark">{{ Object.keys(comments).length }}</span>
    </h5>
    <div class="comment__list">
      <ul class="list-group" role="list">
        <div
          v-for="comment in comments"
          :key="comment.id"
          class="list-group-item justify-content-between"
          role="listitem"
        >
          <div class="comment__body">
            <div class="d-flex justify-content-start">
              <h6 class="mb-1">{{ comment.user | trimUsername }}</h6>
            </div>
            <small class="mb-2">{{ comment.created | formatDate }}</small>
          </div>
          <p class="mb-1">{{ comment.comment }}</p>
        </div>
      </ul>
    </div>
    <div class="comment__form">
      <div class="form-group" :class="[{ 'has-danger': !!errors.comment }]">
        <label id="newComment-label" for="newComment" aria-label="Comment">
          Comment:
        </label>
        <div class="input-group">
          <textarea
            id="newComment"
            v-model="activeComment.comment"
            placeholder="Add comment..."
            class="form-control"
            :class="[
              {
                'form-control-danger': !!errors.comment,
              },
            ]"
            aria-describedby="newComment-label"
            data-cy="comment-box"
          />
          <div v-if="!!errors.comment" class="form-control-feedback">
            {{ errors.comment }}
          </div>
        </div>
      </div>
      <icon-button
        id="submitComment"
        label="Submit"
        type="primary"
        icon="message-circle"
        :disabled="disableSubmit"
        data-cy="comment-save-button"
        @click="postComment"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import { formatDistanceToNowStrict } from "date-fns"
import { IconButton } from "@/components/elements"

export default {
  name: "Comments",
  components: {
    IconButton,
  },
  filters: {
    formatDate(val) {
      return formatDistanceToNowStrict(new Date(val), { addSuffix: true })
    },
    trimUsername(val) {
      return val.endsWith("@arizona.edu") ? val.slice(0, -12) : val || ""
    },
  },
  props: {
    comments: {
      type: Array,
      required: true,
    },
    preventSubmission: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters("checks", ["activeCheck"]),
    ...mapGetters("comments", ["activeComment", "errors"]),
    /** Disable form submission when comment is empty */
    disableSubmit() {
      return this.activeComment.comment.trim() === ""
    },
  },
  methods: {
    ...mapActions("comments", ["post"]),
    ...mapActions("checks", ["fetchCheckById", "updateActiveCheck"]),
    /**
     * Post a comment to the api or, if `preventSubmission` is true, emit @addComment
     * allowing the parent to handle it. E.g. the create modal on the Home page posts after check creation
     */
    postComment() {
      if (this.preventSubmission) {
        this.$emit("addComment", this.activeComment)
        this.activeComment.comment = ""
      } else {
        this.post()
          .then(() => this.fetchCheckById(this.activeCheck.id))
          .then(() => this.updateActiveCheck(this.activeCheck))
          .then(() => (this.activeComment.comment = ""))
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
.comment {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  min-width: 300px;
  max-width: 300px;
  padding: 1rem 0.5rem !important;
  background-color: #f7f7f9;
  align-content: flex-start;

  .comment__header {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    margin-bottom: 1rem;

    .badge {
      margin-left: 0.5rem;
    }
  }

  .comment__list {
    height: 90%;
    overflow-y: auto;
    max-height: 550px;
  }

  .comment__body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }

  .comment__form {
    margin-top: 1rem;

    .form-group {
      margin-bottom: 0.5rem;
    }

    button {
      float: right;
    }
  }
}
</style>
