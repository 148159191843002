<template>
  <div id="csvModal" class="export-csv-modal">
    <modal
      v-show="show"
      v-bind="$attrs"
      title="Export to CSV"
      size="custom"
      :max-width="800"
      role="dialog"
      v-on="$listeners"
      @close="$emit('close')"
    >
      <template #default>
        <div class="view-details__modal">
          <div class="main-modal-content">
            <input-box
              id="fileNameTextBox"
              v-model="csv.fileName"
              type="text"
              label="File name:"
              description="The csv extension does not need to be included in the file name."
              data-cy="export-text-box"
            />
          </div>
        </div>
      </template>
      <template #footer>
        <div slot="footer">
          <icon-button
            class="mr-1"
            label="Close"
            type="accent"
            icon="x"
            @click="$emit('close')"
          />
          <icon-button
            label="Save"
            type="primary"
            icon="save"
            :disabled="!csv.fileName.trim().length"
            data-cy="export-save"
            @click="ExportModal"
          />
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { InputBox, IconButton, Modal } from "@/components/elements"

export default {
  name: "ExportModal",
  components: {
    InputBox,
    IconButton,
    Modal,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    gridApi: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      csv: {
        skipHeader: false,
        skipFooters: false,
        skipGroups: false,
        allColumns: false,
        fileName: "",
        columnSeparator: 0,
        processCellCallback: function (params) {
          // Convert data to something more human readable
          if (params.value === false) {
            return "No"
          } else if (params.value === true) {
            return "Yes"
          } else if (
            params.value &&
            typeof params.value === "string" &&
            params.value.includes(":")
          ) {
            var formDate = new Date(params.value)
            return formDate.toLocaleString("en-US")
          } else if (params.value && params.value.toUpperCase) {
            if (params.value.includes("@")) {
              return params.value
            }
            var splitStr = params.value.toLowerCase().split(" ")
            for (var i = 0; i < splitStr.length; i++) {
              splitStr[i] =
                splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
            }
            return splitStr.join(" ")
          } else {
            return params.value
          }
        },
      },
    }
  },
  methods: {
    ExportModal() {
      if (this.csv.fileName.trim() !== "") {
        this.csv.fileName = this.csv.fileName.trim()
        this.$emit("close")
        this.gridApi.exportDataAsCsv(this.csv)
      }
      this.csv.fileName = ""
    },
  },
}
</script>
