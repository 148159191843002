<template>
  <div class="card mb-2">
    <div class="card-header d-flex justify-content-start align-items-center">
      <h5>Archived Signatures</h5>
      <icon-button
        type="secondary"
        outline
        class="px-1 mx-1 ml-auto"
        :icon="archiveCollapsedState ? 'chevron-down' : 'chevron-up'"
        @click="archiveCollapsedState = !archiveCollapsedState"
      />
    </div>
    <div v-if="!archiveCollapsedState" class="card-block">
      <div
        v-for="value in archivedSignatures"
        :key="value.id"
        slot="content"
        class="list-group-flush"
      >
        <li
          class="list-group-item align-items-start"
          style="flex-direction: column"
        >
          <img :src="value.signature" class="img-fluid" alt="Signature image" />
          <p class="mb-0">{{ value.last_name }}, {{ value.first_name }}</p>
          <small class="text-muted">{{ archivedCreated(value.created) }}</small>
          <p class="mb-0">Reason: {{ value.reason }}</p>
        </li>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDistanceToNowStrict } from "date-fns"
import { IconButton } from "@/components/elements"
export default {
  components: {
    IconButton,
  },
  props: {
    archivedSignatures: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      archiveCollapsedState: true,
    }
  },
  methods: {
    archivedCreated(tempData) {
      return formatDistanceToNowStrict(new Date(tempData))
    },
  },
}
</script>

<style lang="postcss" scoped>
.card-header {
  h5 {
    margin-bottom: 0;
  }
}
</style>
